import React, { Component } from 'react';
import { FormattedMessage } from "react-intl";
import {connect} from "react-redux";
import {Field, reduxForm, reset} from "redux-form";
import messages from "../helpers/messages";
import Message from "../helpers/Message";

class ClientBasicForm extends Component {

  state = {
    showShippingData: false
  };

  renderField = (field) => {
    const {
      input,
      placeholder,
      autoComplete,
      meta: { touched, error }
    } = field;

    return (
      <div className="form__field-input">
        <input
          autoComplete={autoComplete}
          placeholder={placeholder}
          {...input}
        />
        {(touched && error) ? <div className="form__error"><FormattedMessage id={error} /></div> : ''}
      </div>
    );
  };

  allowStart = () => {
    const { dirty, valid } = this.props;

    return dirty && valid;
  };

  onSubmit = () => {
    const { dispatch } = this.props;

    dispatch(reset('ClientBasicForm'));
  };

  onClickChangeShippingData = e => {
    const { showShippingData } = this.state;
    e.preventDefault();

    this.setState({
      showShippingData: !showShippingData
    })
  };

  renderShippingData() {
    const { showShippingData } = this.state;

    if (!showShippingData) {
      return (
        <p>
          <button className="button button-rounded button-dark" onClick={e => this.onClickChangeShippingData(e)}>
            <Message id="shipping_data_change"/>
          </button>
        </p>
      )
    }

    return (
      <div>
        <button className="button button-rounded button-mini" onClick={e => this.onClickChangeShippingData(e)}>
          <Message id="shipping_data_change_hide"/>
        </button>
        <Field
          autoComplete="ship_full_name"
          name="ship_full_name"
          type="text"
          placeholder={messages.form_placeholder_ship_full_name}
          component={this.renderField}
        />
        <Field
          autoComplete="ship_address"
          name="ship_address"
          type="text"
          placeholder={messages.form_placeholder_address}
          component={this.renderField}
        />
        <Field
          autoComplete="ship_post_code"
          name="ship_post_code"
          type="text"
          placeholder={messages.form_placeholder_post_code}
          component={this.renderField}
        />
        <Field
          autoComplete="ship_city"
          name="ship_ship_city"
          type="text"
          placeholder={messages.form_placeholder_city}
          component={this.renderField}
        />
      </div>
    )
  }

  render() {
    const { handleSubmit, onSendClick, email, dirty, valid, basicForm } = this.props;

    return (
      <form className="form client-basic-form" onSubmit={handleSubmit(this.onSubmit)} autoComplete>
          <Field
            autoComplete="firstName"
            name="firstName"
            type="text"
            placeholder={messages.form_placeholder_first_name}
            component={this.renderField}
          />
          <Field
            autoComplete="lastName"
            name="lastName"
            type="text"
            placeholder={messages.form_placeholder_last_name}
            component={this.renderField}
          />
          {!basicForm && (
            <div>
              <Field
                autoComplete="address"
                name="address"
                type="text"
                placeholder={messages.form_placeholder_address}
                component={this.renderField}
              />
              <Field
                autoComplete="postCode"
                name="postCode"
                type="text"
                placeholder={messages.form_placeholder_post_code}
                component={this.renderField}
              />
              <Field
                autoComplete="city"
                name="city"
                type="text"
                placeholder={messages.form_placeholder_city}
                component={this.renderField}
              />
              <h4><Message id="shipping_data"/></h4>
              {this.renderShippingData()}
              <h4><Message id="contact_data_header"/></h4>
            </div>
          )}
          <Field
            autoComplete="nope"
            name="email"
            type="email"
            placeholder={messages.form_placeholder_email}
            validate={email}
            component={this.renderField}
          />
          <Field
            autoComplete="phone"
            name="phone"
            type="text"
            placeholder={messages.form_placeholder_phone}
            component={this.renderField}
            normalize={normalizePhone}
          />
          <button
            onClick={() => onSendClick(dirty, valid)}
            className={"button button-3d button-green button-rounded" + (!this.allowStart() ? " button-disabled" : "")}
          >
            <Message id="form_client_basic_send" />
          </button>
      </form>
    );
  }
}

const validate = ({ firstName, lastName, address, city, postCode, email, phone }, {basicForm}) => {
  const errors = {};

  if (!firstName) {
    errors.firstName =  messages.form_error_required
  }
  if (!lastName) {
    errors.lastName =  messages.form_error_required
  }

  if (!basicForm) {
    if (!city) {
      errors.city =  messages.form_error_required
    }
    if (!postCode) {
      errors.postCode =  messages.form_error_required
    }
    if (!address) {
      errors.address =  messages.form_error_required
    }
  }

  if (!email) {
    errors.email =  messages.form_error_email_required
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
    errors.email =  messages.form_error_email_required
  }

  if (!phone) {
    errors.phone =  messages.form_error_required
  }

  return errors;
};

const normalizePhone = value => {
  if (!value) {
    return value
  }

  return value.replace(/[^\d]/g, '');
};

export default (
  connect()(
    reduxForm({
      validate,
      form: 'ClientBasicForm',
      destroyOnUnmount: false,
    })(ClientBasicForm)
  )
)