import CONSTS from "./consts";

const utils = {
  isDevelopment: () => {
    const { development } = CONSTS;

    return development === 1 // must be like this ==
  },
  getResponse: response => {
    return response.payload.data.response
  },
  getImgErrorImg: () => {
    // return CONSTS.url.baseUrl + '/favicon.png'
    return 'https://beststore.pl/theme/images/nophoto.png';
  },
  formatPrice: price => {
    return `${price.toFixed(2)} zł`;
  }
};

export default utils;