const baseUrl = process.env.REACT_APP_ADDRESS;
const apiUrl = 'https://beststore.pl/';

const CONSTS = {
  url: {
    baseUrl: baseUrl,
    page: '/page/:pageId',
    catalog: '/catalog',
    category: '/catalog/:categoryId',
    product: '/catalog/product/:productId',
    categories: '/catalog/categories',
    cart: '/cart',
    orderShipping: '/order-shipping',
    orderPayment: '/order-payment',
    orderClientData: '/order-client-data',
    summary: '/summary',
    confirm: '/confirm',
    login: '/login',
  },
  apiUrl: {
    test: 'https://venosystems.pl/robots.txt',
    token: apiUrl + 'api/token',
    app: apiUrl + 'api/app',
    search: apiUrl + 'api/app/search?q=:searchQuery',
    homeSlides: apiUrl + 'api/app/rs',
    homeProducts: apiUrl + 'api/app/catalog/products',
    page: apiUrl + 'api/app/page/:pageId',
    category: apiUrl + 'api/app/catalog/category/:categoryId',
    products: apiUrl + 'api/app/catalog/products/:categoryId?page=:page',
    product: apiUrl + 'api/app/catalog/product/:productId',
    categories: apiUrl + 'api/app/categories/main',
    subCategories: apiUrl + 'api/app/categories/sub/:categoryId',
    cart: {
      add: apiUrl + 'api/app/cart/add/:productId',
      get: apiUrl + 'api/app/cart/get',
      shippings: apiUrl + 'api/app/cart/shippings',
      payments: apiUrl + 'api/app/cart/payments',
      inpostMachines: apiUrl + 'api/app/cart/inpost/:postCode_:paymentAvailable',
      placeOrder: apiUrl + 'api/app/cart/placeOrder',
      getOrder: apiUrl + 'api/app/cart/getOrder/:orderId',
    }
  },
  settings: {
    productsPerPage: 20
  },
  types: {
    shippingTypes: {
      shipping: 1,
      personalCollect: 2,
      inpostMachine: 3
    },
    shippingPayment: {
      transfer: 1,
      cashOnCollect: 2,
      cash: 3
    }
  },
  XHRHeaders: {
    headers: {
      // "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      // "Access-Control-Allow-Credential": true,
      // "Access-Control-Allow-Origin": "*",
      // "X-Requested-With": "XMLHttpRequest"
    }
  },

  development: process.env.REACT_APP_DEVELOPMENT,
};

export default CONSTS;