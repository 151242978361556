const messages = {
  buy_now: 'Do koszyka',
  show: 'Zobacz',
  product_page_back: '< wróć',
  products_filter: 'Filtruj',
  catalog_back_to_top: 'Powrót',
  catalog_page: 'Strona: ',
  catalog_total_results: 'Produktów: ',
  catalog_previous_page: 'Poprzednia',
  catalog_next_page: 'Następna',
  shop_cart_header: 'Koszyk',
  shop_cart_place_order: 'Zamawiam',
  shop_cart_choose_shipping: 'Wybierz metodę dostawy',
  shop_cart_no_products: 'Koszyk jest pusty',
  filter_by: 'Filtrowanie',
  filter_status: 'Dostępność',
  filter_vendor: 'Producent',
  shipping_type_shipping: 'Wysyłka kurierem',
  shipping_type_personal_collect: 'Odbiór osobisty',
  shipping_type_inpost_machine: 'Paczkomat InPost',
  choose_client_data_header: 'Twoje dane',
  form_placeholder_first_name: 'Imię',
  form_placeholder_last_name: 'Nazwisko',
  form_placeholder_email: 'E-mail',
  form_placeholder_phone: 'Telefon',
  form_placeholder_ship_full_name: 'Nazwa odbiorcy',
  form_placeholder_address: 'Adres',
  form_placeholder_post_code: 'Kod pocztowy',
  form_placeholder_city: 'Miasto',
  form_placeholder_comment_checkbox: 'Chcę dodać uwagi',
  form_placeholder_comment: 'Chcę dodać uwagi',
  form_placeholder_personal_data: 'Wyrażam zgodę na przetwarzania danych osobowych',
  form_placeholder_rules: 'Akceptuje regulamin',
  form_error_required: 'To pole jest wymagane',
  form_error_email_required: 'Podaj prawidłowy adres e-mail',
  form_client_basic_send: 'Dalej',
  client_data_header: 'Dane do zamówienia',
  choose_shipping: 'Sposób dostawy',
  order_confirm_header: 'Potwierdź zamówienie',
  order_summary_header: 'Podsumowanie',
  order_summary_page_header: 'Dziękujemy za złożenie zamówienia',
  button_order_confirm: 'Zamawiam z obowiązkiem zapłaty',
  post_code_valid_format: 'Np. 01-001',
  choose_inpost_machine: 'Wybierz paczkomat',
  type_your_postal_code: 'Podaj kod pocztowy',
  shipping_data_header: 'Dane dostawy',
  fix_data: 'Popraw',
  choose_payment_header: 'Sposób płatności',
  shipping_data: 'Dane do wysyłki',
  shipping_data_change: 'Wysyłka pod inny adres',
  shipping_data_change_hide: 'Schowaj pola',
  contact_data_header: 'Dane kontaktowe',
  order_no: 'Zamówienie nr '
};

export default messages;