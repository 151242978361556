import {
  API_TEST_SUCCESS,
  GET_TOKEN_SUCCESS,
  GET_APP_SUCCESS,
  SET_BACK_URL,
  ADD_TO_CART,
  DELETE_FROM_CART
} from "./appActions";

import {
  POST_PLACE_ORDER_SUCCESS
} from "./order/orderActions";

const initialState = {
  token: null,
  backUrl: null,
  appData: null,
  shopCartProducts: [],
  test: null
};

function appReducer(state = initialState, action) {
  switch (action.type) {
    case API_TEST_SUCCESS:
      return {
        ...state,
        test: "OK"
      };
    case GET_TOKEN_SUCCESS:
      return {
        ...state,
        token: action.payload.data.response.token
      };
    case GET_APP_SUCCESS:
      return {
        ...state,
        appData: action.payload.data.response
      };
    case SET_BACK_URL:
      return {
        ...state,
        backUrl: action.data.url
      };
    case POST_PLACE_ORDER_SUCCESS:
      return {
        ...state,
        shopCartProducts: []
      };
    case ADD_TO_CART:
      const isExists = state.shopCartProducts.filter(product => {
        return product.id === action.data.product.id
      });

      const shopCartProductsNew = state.shopCartProducts;

      if (isExists.length === 0) {
        shopCartProductsNew.push(action.data.product);
      }

      return {
        ...state,
        shopCartProducts: shopCartProductsNew
      };
    case DELETE_FROM_CART:
      const shopCartProductsNewAfterDelete = state.shopCartProducts.filter(product => {
        return product.id !== action.data.productId
      });

      return {
        ...state,
        shopCartProducts: shopCartProductsNewAfterDelete
      };
    default:
      return state
  }
}

export default appReducer;
