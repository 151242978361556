import React, {Component} from 'react';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Message from "../helpers/Message";
import CartProducts from "../cart/Products";
import CONSTS from "../consts";
import OrderForm from "./OrderForm";
import {placeOrder, getOrder} from "./orderActions";
import utils from "./../utils";

class OrderConfirm extends Component {

  onSendClick = (dirty, valid) => {
    const { dispatch, history, shopCartProducts, shipping, payment, clientData, orderData } = this.props;
    const { url } = CONSTS;

    if (!dirty || !valid) {
      return;
    }

    // @TODO: Add Payment id
    dispatch(placeOrder(shopCartProducts, shipping.id, null, clientData, orderData)).then(response => {
      const responseData = utils.getResponse(response);
      dispatch(getOrder(responseData.orderId)).then(() => {
        history.push(url.summary);
      });
    });
  };

  renderShippingData = () => {
    const { shipping, shippingInpostMachine } = this.props;
    const { types: {shippingTypes} } = CONSTS;

    switch (shipping.type) {
      case shippingTypes.inpostMachine: {
        return (
          <div>
            {shipping.name}<br />
            <strong>{shippingInpostMachine}</strong>
          </div>
        )
      }
      default: {
        return (
          <div>
            {shipping.name}
          </div>
        );
      }
    }
  };

  renderClientData  = () => {
    const { clientData } = this.props;
    const { url } = CONSTS;

    if (clientData) {
      const { firstName, lastName, email, phone } = clientData;

      return (
        <div className="client-data">
          <h4 className="client-data__header">
            <Message id="client_data_header"/>
            <Link to={url.orderClientData} className="button button-mini button-rounded button-light">
              <Message id="fix_data"/>
            </Link>
          </h4>
          <p>
            {`${firstName} ${lastName}`}<br />
            {email}<br />
            {phone}
          </p>
          <h4 className="client-data__header">
            <Message id="shipping_data_header"/>
            <Link to={url.orderShipping} className="button button-mini button-rounded button-light">
              <Message id="fix_data"/>
            </Link>
          </h4>
          {this.renderShippingData()}
        </div>
      )
    }
  };

  renderShopCartProducts = () => {
    const { shopCartProducts } = this.props;

    return (
      <CartProducts
        products={shopCartProducts}
      />
    );
  };

  renderSummary = () => {
    return (
      <div className="client-data">
        <h4 className="client-data__header">
          <Message id="order_summary_header"/>
        </h4>
        <OrderForm onSendClick={this.onSendClick}/>
      </div>
    );
  };

  render() {
    return (
      <div className="order-summary-page">
        <h1>
          <Message id="order_confirm_header"/>
        </h1>
        {this.renderClientData()}
        {this.renderShopCartProducts()}
        {this.renderSummary()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  clientData: state.form.ClientBasicForm && state.form.ClientBasicForm.values,
  orderData: state.form.OrderForm && state.form.OrderForm.values,
  shopCartProducts: state.appReducer.shopCartProducts,
  shopCartProductsCount: state.appReducer.shopCartProducts.length,
  shipping: state.orderReducer.shipping,
  payment: state.orderReducer.payment,
  shippingInpostMachine: state.orderReducer.shippingInpostMachine,
});

export default connect(mapStateToProps)(OrderConfirm);