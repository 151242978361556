import React, {Component} from 'react';
import { Link } from "react-router-dom";
import CONSTS from "./../consts";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

class FooterSubPages extends Component {

  handleOnClick() {
    const {setCategoriesOpen, expandId} = this.props;

    expandId(null);
    setCategoriesOpen(false);
    window.scrollTo(0, 0);
  }

  render() {
    const {pages} = this.props;

    return (
      <ul>
        {pages.map(page => {
          return (
            <li>
              <Link onClick={() => this.handleOnClick()} to={CONSTS.url.page.replace(':pageId', page.id)}>
                {page.name}
              </Link>
            </li>
          )
        })}
      </ul>
    )
  }
}

function FooterPages({pages, setCategoriesOpen, expandedId, expandId}) {
  const isExpanded = (expandedId, page) => {
    return expandedId === page.id;
  };

  return pages.map(page => {
    const expanded = isExpanded(expandedId, page);

    return (
      <div className={"footer__page" + (expanded ? " footer__page--expanded" : "")}>
        <button className="footer-main-link" onClick={expanded ? () => expandId(null) : () => expandId(page.id)}>
          {!expanded ? (
            <ChevronRightIcon />
          ) : (
            <ExpandMoreIcon />
          )}
          {page.name}
        </button>
        <FooterSubPages expandId={expandId} pages={page.pages} setCategoriesOpen={setCategoriesOpen}/>
      </div>
    )
  });
}

class Footer extends Component {

  state = {
    expandedId: null
  };

  expandId = id => {
    this.setState({
      expandedId: id
    })
  };

  render() {
    const { expandedId } = this.state;
    const {copyright, pages, setCategoriesOpen} = this.props;

    return (
      <div className="footer">
        <FooterPages pages={pages} expandId={this.expandId.bind(this)} setCategoriesOpen={setCategoriesOpen} expandedId={expandedId}/>
        <div className="copyright" dangerouslySetInnerHTML={{ __html: copyright }} />
      </div>
    );
  }
}

export default Footer;
