import React, {Component} from 'react';
import Shippings from "./order/Shippings";

class Order extends Component {

  render() {
    const { history } = this.props;

    return (
      <Shippings history={history} />
    );
  }
}

export default Order;
