import React, {Component} from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import CONSTS from "./../consts";
import Icon from './Icon';
import ICONS from './../iconList';
import Search from "./navigation/Search";
import {getSearch} from "../appActions";
import utils from "./../utils";

class Navigation extends Component {

  state = {
    searchString: '',
    searchOpened: false,
    searchResults: []
  };

  searchOpen() {
    this.setState({
      searchString: '',
      searchOpened: true,
      searchResults: []
    }, () => {
      this.searchInput.focus();
    })
  }

  onClickSearch = () => {
    this.setState({
      searchOpened: !this.state.searchOpened,
      searchResults: []
    })
  };

  onChangeSearch(e) {
    const { dispatch } = this.props;
    const searchQ = e.target.value;

    this.setState({
      searchString: searchQ
    });

    if (e.target.value.length > 2) {
      dispatch(getSearch(searchQ)).then(response => {
        this.setState({
          searchResults: utils.getResponse(response)
        })
      })
    } else {
      this.setState({
        searchResults: []
      })
    }
  }

  hasResults = () => {
    const { searchResults } = this.state;

    return (searchResults.products || searchResults.categories || searchResults.vendors);
  };

  render() {
    const { categoriesOpen, setCategoriesOpen, handleToggleCategories, shopCartProductsCount } = this.props;
    const { cart, login } = CONSTS.url;
    const { searchString, searchOpened, searchResults } = this.state;

    if (searchOpened) {
      return (
        <div className="search-container">
          <div className="search">
            <div className="search-input">
              <input placeholder="Szukaj..." onChange={e => this.onChangeSearch(e)} ref={element => this.searchInput = element}/>
            </div>
            {searchString.length > 2 && (
              <button className="search-button">
                <Icon icon={ICONS.SEARCH}/>
              </button>
            )}
            <button onClick={() => this.onClickSearch()}>
              <Icon icon={ICONS.CLOSE}/>
            </button>
          </div>
          {this.hasResults() && (
            <div className="search-results">
              <Search results={searchResults} onClickSearch={this.onClickSearch.bind(this)}/>
            </div>
          )}
        </div>
      );
    }

    return (
      <div className="navigation">
        <Link onClick={() => setCategoriesOpen(false)} to={login}>
          <button>
            <Icon icon={ICONS.USER} />
          </button>
        </Link>
        <button onClick={() => this.searchOpen()}>
          <Icon icon={ICONS.SEARCH} />
        </button>
        <Link onClick={() => setCategoriesOpen(false)} to={cart}>
          <button>
            {shopCartProductsCount > 0 && (
              <span className="shop-cart-products-count">
              {shopCartProductsCount}
            </span>
            )}
            <Icon icon={ICONS.SHOP_CART} />
          </button>
        </Link>
        <button onClick={() => handleToggleCategories()}>
          {categoriesOpen ? (
            <Icon icon={ICONS.CLOSE} />
          ) : (
            <Icon icon={ICONS.BURGER} />
          )}
        </button>
      </div>
    );
  }
}

export default connect()(Navigation);
