import {
  GET_ORDER_SHIPPINGS_SUCCESS,
  GET_ORDER_PAYMENTS_SUCCESS,
  POST_PLACE_ORDER_SUCCESS,
  GET_ORDER_SUCCESS,
  SET_SHIPPING
} from "./orderActions";

const initialState = {
  orderData: null,
  shippings: [],
  payments: [],
  shipping: null,
  shippingInpostMachine: null
};

function orderReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ORDER_SHIPPINGS_SUCCESS:
      const newShippings = action.payload.data.response;
      return {
        ...state,
        shippings: newShippings
      };
    case GET_ORDER_PAYMENTS_SUCCESS:
      const newPayments = action.payload.data.response;
      return {
        ...state,
        payments: newPayments
      };
    case POST_PLACE_ORDER_SUCCESS:
      return {
        ...state,
        shippings: [],
        payments: [],
      };
    case GET_ORDER_SUCCESS:
      return {
        ...state,
        orderData: action.payload.data.response
      };
    case SET_SHIPPING:
      return {
        ...state,
        shipping: action.data.shipping,
        shippingInpostMachine: action.data.inpostMachine,
      };
    default:
      return state
  }
}

export default orderReducer;
