import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {getCategories, getSubCategories} from "./../appActions";
import Button from '@material-ui/core/Button';
import CONSTS from "./../consts";
import Message from "../helpers/Message";
import CircularProgress from '@material-ui/core/CircularProgress';

class Categories extends Component {

  state = {
    currentId: null,
    categories: null,
    loading: true
  };

  componentDidMount() {
    this.loadCategory(null);
  }

  loadCategory(categoryId) {
    const {dispatch} = this.props;

    this.setState({
      loading: true
    });

    const actionToDispatch = categoryId === null
      ? getCategories()
      : getSubCategories(categoryId);

    dispatch(actionToDispatch).then(response => {
      this.setState({
        currentId: categoryId,
        categories: response.payload.data.response,
        loading: false
      });
    });
  }

  renderCategory = category => {
    return (
      <div>
        {category.photoUrl && (
          <img src={category.photoUrl} />
        )}
        <h5>
          {category.name}
          {category.productsCount &&
          <em>({category.productsCount})</em>
          }
        </h5>
      </div>
    )
  }

  renderCategories() {
    const { setCategoriesOpen } = this.props;
    const {categories} = this.state;
    const { url: {category: categoryUrl}} = CONSTS;

    if (categories === null) {
      return null;
    }

    return categories.map(category => {
      return (
        <div className="sub-category" key={category.id}>
          {category.hasSubCategories ? (
            <div onClick={() => this.loadCategory(category.id)}>
              {this.renderCategory(category)}
            </div>
          ) : (
            <Link onClick={() => setCategoriesOpen()} to={categoryUrl.replace(':categoryId', category.id)}>
              {this.renderCategory(category)}
            </Link>
          )}
        </div>
      )
    })
  }

  render() {
    const {currentId, loading} = this.state;

    if (loading) {
      return <CircularProgress />
    }

    return (
      <div>
        {currentId &&
          <Button onClick={() => this.loadCategory(null)}>
            <Message id="catalog_back_to_top" />
          </Button>
        }
        <div className={"categories" + (currentId ? " categories--sub-categories" : "")}>
          {this.renderCategories()}
        </div>
      </div>
    );
  }
}

export default connect()(Categories);
