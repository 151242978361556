import React from 'react';
import utils from "../utils";

function CartProducts({products, remove, removeFromCart}) {
  const addDefaultSrc = ev => {
    ev.target.src = utils.getImgErrorImg()
  };

  return products.map(product => {
    return (
      <div className="shop-cart-product">
        <div className="shop-cart-product__image">
          <img
            src={product.photoUrl}
            alt={product.name}
            onError={addDefaultSrc}
          />
        </div>
        <div className="shop-cart-product__data">
          <h3>
            {product.name}
          </h3>
          {product.isPromo ? (
            <h5 className="price price--promo">
              {utils.formatPrice(product.price)}
              <del>{utils.formatPrice(product.regularPrice)}</del>
            </h5>
          ) : (
            <h5 className="price">{utils.formatPrice(product.price)}</h5>
          )}
        </div>
        {remove && (
        <div className="shop-cart-product__delete">
          <button onClick={() => removeFromCart(product.id)} className="button button-rounded button-red button-mini">
            X
          </button>
        </div>
        )}
      </div>
    )
  })
}

export default CartProducts;
