import React, {Component} from 'react';
import { connect } from "react-redux";
import {addToCart, getProduct} from "./../appActions";
import utils from "../utils";
import Message from "../helpers/Message";
import Icon from "../base/Icon";
import ICONS from "../iconList";
import consts from "../consts";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import CircularProgress from '@material-ui/core/CircularProgress';

class Product extends Component {

  state = {
    productId: null,
    product: null,
    loading: true
  };

  componentDidMount() {
    const { dispatch, match } = this.props;
    const { params: { productId } } = match;

    dispatch(getProduct(productId)).then(response => {
      this.setState({
        productId: productId,
        product: utils.getResponse(response),
        loading: false
      });
    });
  }

  componentDidUpdate() {
    const { productId: currentProductId } = this.state;
    const { match } = this.props;
    const { params: { productId } } = match;

    if (currentProductId !== productId) {
      this.getProduct(productId);
    }
  }

  getProduct = productId => {
    const { dispatch } = this.props;

    this.setState({
      loading: true,
    });

    dispatch(getProduct(productId)).then(response => {
      this.setState({
        productId: productId,
        product: utils.getResponse(response),
        loading: false
      });
    });
  };

  back() {
    const { history, backUrl } = this.props;

    this.setState({
      productId: null,
      product: null,
      loading: true
    }, () => {
      history.push(backUrl);
    });
  }

  addDefaultSrc = ev => {
    ev.target.src = utils.getImgErrorImg()
  };

  renderVendor = product => {
    const { vendorName, vendorLogoUrl } = product;

    if (vendorLogoUrl) {
      return (
        <img src={vendorLogoUrl} alt={vendorName} />
      );
    }

    return (
      <h4>{vendorName}</h4>
    );
  };

  renderBackButton() {
    const { backUrl } = this.props;

    return backUrl && (
      <div className="product-page__back-button">
        <button className="button button-mini button-rounded" onClick={() => this.back()}>
          <Message id="product_page_back" />
        </button>
      </div>
    )
  }

  addToCart(product) {
    const { dispatch, history } = this.props;
    const { url: {cart}} = consts;

    dispatch(addToCart(product));
    history.push(cart);
  }

  renderSlider = photos => {
    if (photos.length > 0) {
      return (
        <Carousel showIndicators={false} showStatus={false} autoPlay={true}>
          {photos.map(photo => {
            return (
              <div className="veno-slide" style={{height: '250px', backgroundImage: `url(${photo})`}}>
                <img src={photo} />
              </div>
            )
          })}
        </Carousel>
      )
    }
  };

  render() {
    const { product, loading } = this.state;

    if (loading) {
      return <CircularProgress />;
    }

    return (
      <div className="product-page">
        {this.renderBackButton()}
        <h1>{product.name}</h1>
        {this.renderVendor(product)}
        {this.renderSlider(product.photos)}
        <div className="product-page__data">
          {`ID: #${product.id}`}<br />
          {`SKU: ${product.sku}`}<br />
        </div>
        <div className="product-page__attrs">

        </div>
        {product.desc !== '' && (
          <div className="product-page__desc">
            <h4>Opis</h4>
            <div dangerouslySetInnerHTML={{ __html: product.desc }} />
          </div>
        )}
        <div className="product-page__spec">

        </div>
        <div className="product-page__buy-bar">
          <div className="price">
            {product.isPromo ? (
              <h5 className="price price--promo">
                <del>{utils.formatPrice(product.regularPrice)}</del>
                {utils.formatPrice(product.price)}
              </h5>
            ) : (
              <h5 className="price">{utils.formatPrice(product.price)}</h5>
            )}
          </div>
          <button className="button button-3d button-green button-rounded" onClick={() => this.addToCart(product)}>
            <Message id="buy_now" />
            <Icon icon={ICONS.SHOP_CART} />
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  backUrl: state.appReducer.backUrl
});

export default connect(mapStateToProps)(Product);
