import React, {Component} from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Message from "./helpers/Message";
import {deleteFromCart} from "./appActions"
import CONSTS from "./consts";
import CartProducts from "./cart/Products";

class Cart extends Component {

  removeFromCart = productId => {
    const { dispatch } = this.props;

    dispatch(deleteFromCart(productId));
  };

  renderProducts() {
    const { shopCartProducts } = this.props;

    if (shopCartProducts.length === 0) {
      return (
        <p>
          <Message id="shop_cart_no_products" />
        </p>
      );
    }

    return (
      <CartProducts
        products={shopCartProducts}
        remove
        removeFromCart={this.removeFromCart.bind(this)}
      />
    );
  }

  render() {
    const { shopCartProducts } = this.props;
    const { url } = CONSTS;

    return (
      <div className="shop-cart-page">
        <h2>
          <Message id="shop_cart_header" />
        </h2>
        {this.renderProducts()}
        {shopCartProducts.length > 0 && (
          <div className="shop-cart-page__button-cont">
            <Link to={url.orderShipping} className="button button-3d button-green button-rounded button-place-order">
              <Message id="shop_cart_choose_shipping" />
            </Link>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  shopCartProducts: state.appReducer.shopCartProducts
});

export default connect(mapStateToProps)(Cart);
