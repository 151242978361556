import React, { Component } from 'react';
import { connect } from "react-redux";
import {getHomeSlides, getHomeProducts} from "./../appActions";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import utils from "../utils";
import Products from "../catalog/Products";
import consts from "../consts";

class Home extends Component {

  state = {
    slides: [],
    products: null
  };

  componentWillMount() {
    const { dispatch } = this.props;

    dispatch(getHomeSlides()).then(response => {
      this.setState({
        slides: utils.getResponse(response)
      });
    });

    dispatch(getHomeProducts()).then(response => {
      this.setState({
        products: utils.getResponse(response)
      });
    });
  }

  showProduct = productId => {
    const { history } = this.props;
    const { url: { product } } = consts;

    history.push(product.replace(':productId', productId));
  };

  renderProducts() {
    const { products } = this.state;

    return products === null
      ? <CircularProgress />
      : <div className="products"><Products products={products.rows} showProduct={this.showProduct}/></div>;
  }

  renderSlider() {
    const { slides } = this.state;

    if (slides.length > 0) {
      return (
        <div>
          <Carousel showThumbs={false} showIndicators={false} showStatus={false} autoPlay={true}>
            {slides.map(slide => {
              return (
                <div className="veno-app-slide" style={{backgroundImage: `url(${slide.imageUrl})`}} />
              )
            })}
          </Carousel>
        </div>
      )
    }
  }

  render() {
    return (
      <div className="home-page">
        {this.renderSlider()}
        {this.renderProducts()}
      </div>
    );
  }
}

export default connect()(Home);
