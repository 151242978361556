import React, {Component} from 'react';
import { connect } from "react-redux";
import utils from "../utils";
import {getPage} from "../appActions";

class Page extends Component {

  state = {
    pageId: null,
    page: null
  };

  componentDidUpdate() {
    const {match} = this.props;
    const {params: {pageId}} = match;
    const {pageId: currentPageId} = this.state;

    if (pageId !== currentPageId) {
      this.getPage(pageId);
    }
  }

  componentDidMount() {
    const { match } = this.props;
    const { params: { pageId } } = match;

    this.getPage(pageId);
  }

  getPage(pageId) {
    const {dispatch} = this.props;

    dispatch(getPage(pageId)).then(response => {
      this.setState({
        pageId: pageId,
        page: utils.getResponse(response)
      });
    });
  }

  close() {
    const { history, backUrl } = this.props;

    this.setState({
      product: null
    }, () => {
      history.push(backUrl);
    });
  }

  render() {
    const { page } = this.state;

    if (page === null) {
      return null;
    }

    return (
      <div className="info-page">
        {/*<button className="product-page__back-button" onClick={() => this.close()}>Back</button>*/}
        <div dangerouslySetInnerHTML={{ __html: page.content }} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  backUrl: state.appReducer.backUrl
});

export default connect(mapStateToProps)(Page);
