import React from 'react';
import { hot } from 'react-hot-loader/root';
import axios from 'axios';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import logger from 'redux-logger'
import axiosMiddleware from 'redux-axios-middleware';
import venoApp from './reducers';
import CONSTS from './consts';
import App from './App';
import utils from "./utils";
import {getToken,getAppData} from "./appActions";

const client = axios.create({ baseURL: CONSTS.url.baseUrl, responseType: 'json', headers: CONSTS.XHRHeaders.headers});
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(venoApp, composeEnhancers(
  applyMiddleware(axiosMiddleware(client), logger)
));

if (!utils.isDevelopment()) {
  // Add Google Analyitcs
}

window.store = store;

class AppContainer extends React.Component {

  componentWillMount() {
    store.dispatch(getToken());
    store.dispatch(getAppData());
  }

  render() {
    return (
      <Provider store={store}>
        <App translations={store.getState().appReducer.translations} />
      </Provider>
    );
  }
}

export default hot(AppContainer);
