import React, {Component} from 'react';
import { connect } from "react-redux";
import Message from "../helpers/Message";
import CONSTS from "../consts";
import Button from '@material-ui/core/Button';
import {getOrderPayments, setPayment} from "./orderActions";

class Payments extends Component {

  state = {
    selected: null
  };

  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(getOrderPayments());
  }

  isShippingSelected = payment => {
    const { selected } = this.state;

    if (selected === null) {
      return false
    }

    return selected.id === payment.id;
  };

  goOrder() {
    const { selected } = this.state;
    const { dispatch, history } = this.props;
    const { url } = CONSTS;

    setTimeout(() => {
      dispatch(setPayment(selected));
      history.push(url.orderClientData);
    }, 100)
  }

  onPaymentClick = payment => {
    this.setState({
      selected: payment
    }, () => {
      setTimeout(() => {
        this.goOrder();
      }, 100)
    })
  };

  renderList() {
    const { payments } = this.props;

    if (payments.length === 0) {
      return null;
    }

    return (
      <div className="payments">
        {payments.map(payment => {
          return (
            <div className={this.isShippingSelected(payment) ? "payment payment--selected" : "payment"} onClick={() => this.onPaymentClick(payment)}>
              <h4 key={payment.id} dangerouslySetInnerHTML={{ __html: payment.name }} />
              <div className="description" dangerouslySetInnerHTML={{ __html: payment.description }} />
            </div>
          )
        })}
      </div>
    );
  }

  render() {
    const { history } = this.props;
    const { url } = CONSTS;

    return (
      <div className="client-data-page">
        <Button onClick={() => history.push(url.orderShipping)}>
          <Message id="catalog_back_to_top" />
        </Button>
        <h1>
          <Message id="choose_payment_header"/>
        </h1>
        {this.renderList()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  payments: state.orderReducer.payments
});

export default connect(mapStateToProps)(Payments);
