import React from 'react';
import {FormattedMessage} from "react-intl";
import message from "./messages";

function Message({id}) {
  return (
    <FormattedMessage id={id} defaultMessage={message[id]} />
  )
}

export default Message;