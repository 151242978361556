import React, {Component} from 'react';
import {connect} from "react-redux";
import Filter from "./Filter"
import Message from "./../helpers/Message"
import CloseIcon from '@material-ui/icons/Close';

class Filters extends Component {
  render() {
    const {filters, vendors, statuses, onClickFilter, onClickFilterOption} = this.props;

    return (
      <div className="filters">
        <h2 onClick={() => onClickFilter()}>
          <Message id="filter_by" />
          <CloseIcon/>
        </h2>
        <Filter filters={filters} name="filter_status" data={statuses} onClickFilterOption={onClickFilterOption} url="s_statuses[]" />
        <Filter filters={filters} name="filter_vendor" data={vendors} onClickFilterOption={onClickFilterOption} url="s_manufacturers[]" />
      </div>
    );
  }
}

export default connect()(Filters);
