import React, {Component} from 'react';
import { connect } from "react-redux";
import Message from "../helpers/Message";
import {getOrder} from "./orderActions";

class OrderSummary extends Component {

  componentDidMount() {
    const { dispatch, orderData } = this.props;

    if (orderData === null) {
      // @TODO: Tmp for developing
      dispatch(getOrder(1));
    }
  }

  renderOrderData() {
    const { orderData } = this.props;

    if (orderData === null) {
      return null;
    }

    return (
      <div className="order-data">
        <h1><Message id="order_no" /> {orderData.id}</h1>
        <table>
          <tr>
            <td>
              <td>{orderData.created}</td>
            </td>
          </tr>
          <tr>
            <td>
              <td>{orderData.paymentAmount}</td>
            </td>
          </tr>
        </table>
      </div>
    );
  }

  render() {
    return (
      <div className="order-summary-page">
        <h3>
          <Message id="order_summary_page_header"/>
        </h3>
        {this.renderOrderData()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  orderData: state.orderReducer.orderData
});

export default connect(mapStateToProps)(OrderSummary);
