import React, {Component} from 'react';
import { connect } from "react-redux";
import Message from "../helpers/Message";
import ClientBasicForm from "../order/ClientBasicForm";
import CONSTS from "../consts";
import Button from '@material-ui/core/Button';

class Client extends Component {

  onSendClick = (dirty, valid) => {
    const { history } = this.props;
    const { url } = CONSTS;

    if (!dirty || !valid) {
      return;
    }

    history.push(url.confirm);
  };

  showBasicForm = () => {
    const { shipping: {type} } = this.props;
    const { types: {shippingTypes: { inpostMachine, personalCollect }} } = CONSTS;

    return type === inpostMachine || type === personalCollect;
  };

  render() {
    const { history } = this.props;
    const { url } = CONSTS;
    const basicForm = this.showBasicForm();

    return (
      <div className="client-data-page">
        <Button onClick={() => history.push(url.orderShipping)}>
          <Message id="catalog_back_to_top" />
        </Button>
        <h1>
          <Message id="choose_client_data_header"/>
        </h1>
        <ClientBasicForm basicForm={basicForm} onSendClick={this.onSendClick}/>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  shipping: state.orderReducer.shipping
});

export default connect(mapStateToProps)(Client);
