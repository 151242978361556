import React, {Component} from 'react';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import utils from "../../utils";
import CONSTS from "../../consts";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

class Search extends Component {

  state = {
    tab: 0
  };

  handleChange = (e, newValue) => {
    this.setState({
      tab: newValue
    })
  };

  renderCategories() {
    const { url } = CONSTS;
    const { results, onClickSearch } = this.props;

    if (results.categories.rows === null) {
      return null;
    }

    return results.categories.rows.map(category => {
      return (
        <Link to={url.category.replace(':categoryId', category.id)} className="search-category" onClick={() => onClickSearch()}>
          <div  className="search-category__data">
            <h3>
              {category.nodes} / <strong>{category.name}</strong>
            </h3>
          </div>
        </Link>
      )
    })
  }

  renderProducts() {
    const { url } = CONSTS;
    const { results, onClickSearch } = this.props;

    if (results.products.rows === null) {
      return null;
    }

    return results.products.rows.map(product => {
      return (
        <Link to={url.product.replace(':productId', product.id)} className="search-product" onClick={() => onClickSearch()}>
          <div className="search-product__image">
            <img
              src={product.photoUrl}
              alt={product.name}
            />
          </div>
          <div  className="search-product__data">
            <h3>
              {product.name}
            </h3>
            {product.isPromo ? (
              <h5 className="price price--promo">
                {utils.formatPrice(product.price)}
                <del>{utils.formatPrice(product.regularPrice)}</del>
              </h5>
            ) : (
              <h5 className="price">{utils.formatPrice(product.price)}</h5>
            )}
          </div>
        </Link>
      )
    })
  }

  render() {
    const { tab } = this.state;
    const { results } = this.props;

    return (
      <div>
      <Paper square>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={this.handleChange}
          aria-label="disabled tabs example"
        >
          <Tab label={`Produkty (${results.products.count})`} {...a11yProps(0)} />
          <Tab label={`Kategorie (${results.categories.count})`} {...a11yProps(1)} />
        </Tabs>
      </Paper>
      <SwipeableViews
        axis={'x'}
        index={tab}
        onChangeIndex={this.handleChange}
      >
        <TabPanel value={tab} index={0} dir={'ltr'}>
          {this.renderProducts()}
        </TabPanel>
        <TabPanel value={tab} index={1} dir={'ltr'}>
          {this.renderCategories()}
        </TabPanel>
      </SwipeableViews>
      </div>
    );
  }
}

export default Search;
