import React, { Component } from 'react';
import { FormattedMessage } from "react-intl";
import {connect} from "react-redux";
import {Field, reduxForm, reset} from "redux-form";
import messages from "../helpers/messages";
import Message from "../helpers/Message";
import FormTextarea from "../base/form/textarea";

class OrderForm extends Component {

  renderCheckbox = field => {
    const {
      input,
      meta: { touched, error },
      label
    } = field;

    return (
      <div className="form__field-checkbox">
        <label>
          <div className="checkbox">
            <input
              type="checkbox"
              checked={input.value}
              {...input}
            />
          </div>
          <span>{label}</span>
        </label>
        {(touched && error) ? <div className="form__error"><FormattedMessage id={error} /></div> : ''}
      </div>
    );
  };

  allowStart = () => {
    const { dirty, valid } = this.props;

    return dirty && valid;
  };

  onSubmit = () => {
    const { dispatch } = this.props;

    dispatch(reset('OrderForm'));
  };

  render() {
    const { handleSubmit, onSendClick, formValues, dirty, valid } = this.props;

    return (
      <form className="form client-basic-form" onSubmit={handleSubmit(this.onSubmit)} autoComplete="off">
          <Field
            name="commentCheckbox"
            label={messages.form_placeholder_comment_checkbox}
            component={this.renderCheckbox}
          />
          {formValues && formValues.commentCheckbox && (
            <Field
              name="comment"
              label={messages.form_placeholder_comment}
              component={FormTextarea}
            />
          )}
          <Field
            name="personalData"
            label={messages.form_placeholder_personal_data}
            component={this.renderCheckbox}
          />
          <Field
            name="rules"
            label={messages.form_placeholder_rules}
            component={this.renderCheckbox}
          />
        <button
          onClick={() => onSendClick(dirty, valid)}
          className={"button button-3d button-green button-rounded button-place-order" + (!this.allowStart() ? " button-disabled" : "")}
        >
          <Message id="button_order_confirm" />
        </button>
      </form>
    );
  }
}

const validate = ({ personalData, rules }) => {
  const errors = {};

  if (!personalData) {
    errors.personalData =  messages.form_error_required
  }

  if (!rules) {
    errors.rules =  messages.form_error_required
  }

  return errors;
};

const mapStateToProps = state => ({
  formValues: state.form.OrderForm && state.form.OrderForm.values,
});

export default (
  connect(mapStateToProps)(
    reduxForm({
      validate,
      form: 'OrderForm',
      destroyOnUnmount: false,
    })(OrderForm)
  )
)