import React from 'react';
import Message from "../helpers/Message";
import utils from "../utils";

function Products({products, showProduct}) {
  const addDefaultSrc = ev => {
    ev.target.src = utils.getImgErrorImg()
  };

  return products.map(product => {
    return (
      <div className="product" key={product.id}>
        <div className="product__image" onClick={() => showProduct(product.id)}>
          <img
            src={product.photoUrl}
            alt={product.name}
            onError={addDefaultSrc}
          />
        </div>
        <div className="product__data" onClick={() => showProduct(product.id)}>
          <h4>{product.name}</h4>
          {product.isPromo ? (
            <h5 className="price price--promo">
              <del>{utils.formatPrice(product.regularPrice)}</del>
              {utils.formatPrice(product.price)}
            </h5>
          ) : (
            <h5 className="price">{utils.formatPrice(product.price)}</h5>
          )}
        </div>
        <div className="product__buttons">
          <button className="button button-rounded button-mini" onClick={() => showProduct(product.id)}>
            <Message id="show"/>
          </button>
        </div>
      </div>
    )
  })
}

export default Products;
