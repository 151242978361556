import React from 'react';
import {connect} from "react-redux";
import {IntlProvider} from 'react-intl';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Catalog from "./Catalog";
import Categories from "./catalog/Categories";
import Product from "./catalog/Product";
import Home from "./base/Home"
import Login from "./base/Login"
import Header from "./base/Header";
import Footer from "./base/Footer";
import CONSTS from "./consts";
import Page from "./base/Page";
import Cart from "./Cart";
import Order from "./Order"
import OrderClient from "./order/Client";
import OrderConfirm from "./order/Confirm";
import OrderSummary from "./order/Summary";
import Payments from "./order/Payments";

class App extends React.Component {

  state = {
    categoriesOpen: false
  };

  setCategoriesOpen = categoriesOpen => {
    this.setState({
      categoriesOpen: categoriesOpen
    })
  };

  toggleCategories = () => {
    this.setState({
      categoriesOpen: !this.state.categoriesOpen
    })
  };

  render() {
    const {appData,shopCartProductsCount} = this.props;
    const {categoriesOpen} = this.state;
    const {url} = CONSTS;

    if (appData === null) {
      return null;
    }

    return (
      <IntlProvider locale="pl">
        <div className="App">
          <Router>
            <Header
              logoUrl={appData.logoUrl}
              name={appData.name}
              shopCartProductsCount={shopCartProductsCount}
              categoriesOpen={categoriesOpen}
              setCategoriesOpen={this.setCategoriesOpen.bind(this)}
              handleToggleCategories={this.toggleCategories.bind(this)}
            />
            {categoriesOpen ? (
              <Categories setCategoriesOpen={this.setCategoriesOpen.bind(this)} />
              ) : (
              <Switch>
                <Route exact path={url.catalog} component={Catalog}/>
                <Route exact path={url.category} component={Catalog}/>
                <Route exact path={url.product} component={Product}/>
                <Route exact path={url.cart} component={Cart}/>
                <Route exact path={url.orderShipping} component={Order}/>
                <Route exact path={url.orderPayment} component={Payments}/>
                <Route exact path={url.orderClientData} component={OrderClient}/>
                <Route exact path={url.confirm} component={OrderConfirm}/>
                <Route exact path={url.summary} component={OrderSummary}/>
                <Route exact path={url.page} component={Page}/>
                <Route exact path={url.login} component={Login}/>
                <Route exact path="/" component={Home}/>
                <Route exact path="" component={Home}/>
              </Switch>
              )
            }
            <Footer copyright={appData.copyright} pages={appData.pages} setCategoriesOpen={this.setCategoriesOpen.bind(this)}/>
          </Router>
        </div>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => ({
  appData: state.appReducer.appData,
  shopCartProductsCount: state.appReducer.shopCartProducts.length
});

export default connect(mapStateToProps)(App);
