import React from "react";
import {Link} from "react-router-dom";
import Navigation from "./Navigation";

function Header({logoUrl, name, categoriesOpen, setCategoriesOpen, handleToggleCategories, shopCartProductsCount}) {
  return (
    <div className="header">
      <Link
        className="logo"
        style={{backgroundImage: `url(${logoUrl})`}}
        onClick={() => setCategoriesOpen(false)} to="/"
      />
      <Navigation
        shopCartProductsCount={shopCartProductsCount}
        categoriesOpen={categoriesOpen}
        setCategoriesOpen={setCategoriesOpen}
        handleToggleCategories={handleToggleCategories}
      />
    </div>
  );
}

export default Header;
