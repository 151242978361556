import React, {Component} from 'react';
import { connect } from "react-redux";
import {getProducts, addToCart, setBackUrl, getCategory} from "./appActions";
import utils from './utils';
import consts from './consts';
import Message from "./helpers/Message";
import Icon from "./base/Icon";
import ICONS from "./iconList";
import Filters from "./catalog/Filters";
import CircularProgress from '@material-ui/core/CircularProgress';

class Catalog extends Component {

  state = {
    categoryData: null,
    categoryId: null,
    page: 1,
    products: null,
    total: null,
    filters: {},
    showFilters: false,
    loading: true
  };

  getCategoryId() {
    const { match } = this.props;
    const { params: {categoryId} } = match;

    return categoryId;
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const categoryId = this.getCategoryId();

    dispatch(getCategory(categoryId)).then(response => {
      this.setState({
        categoryId: categoryId,
        categoryData: utils.getResponse(response)
      });

      this.getProducts(categoryId);
    });
  }

  componentDidUpdate() {
    const { dispatch } = this.props;
    const { categoryId } = this.state;
    const routeCategoryId = this.getCategoryId();

    if (categoryId !== routeCategoryId) {
      dispatch(getCategory(routeCategoryId)).then(response => {
        this.setState({
          categoryId: routeCategoryId,
          categoryData: utils.getResponse(response)
        });

        this.getProducts(routeCategoryId);
      });
    }
  }

  getProducts = (categoryId, page = 1, filters = []) => {
    const { dispatch } = this.props;

    this.setState({
      loading: true
    });

    dispatch(getProducts(categoryId, page, filters)).then(response => {
      const data = utils.getResponse(response);
      this.setState({
        products: data.rows,
        total: data.count,
        loading: false
      });

      window.scrollTo(0, 0);
    });
  };

  onClickFilterOption = filters => {
    const { categoryId } = this.state;
    this.setState({
      showFilters: false,
      filters: filters
    }, () => {
      this.getProducts(categoryId, 1, filters);
    });
  };

  addToCart(product) {
    const { dispatch, history } = this.props;
    const { url: {cart}} = consts;

    dispatch(addToCart(product));
    history.push(cart);
  }

  showProduct(productId) {
    const { dispatch, history } = this.props;
    const { url: { product } } = consts;

    dispatch(setBackUrl(history.location.pathname));
    history.push(product.replace(':productId', productId));
  }

  addDefaultSrc = (ev) => {
    ev.target.src = utils.getImgErrorImg()
  };

  nextPage(categoryId) {
    const { page, filters } = this.state;
    const newPage = page + 1;

    this.setState({
      page: newPage
    }, () => {
      this.getProducts(categoryId, newPage, filters);
    })
  }

  previousPage(categoryId) {
    const { page, filters } = this.state;
    const newPage = page - 1;

    this.setState({
      page: newPage
    }, () => {
      this.getProducts(categoryId, newPage, filters);
    })
  }

  renderPagination() {
    const { match } = this.props;
    const { params: {categoryId} } = match;
    const { total, page } = this.state;
    const { settings: { productsPerPage }} = consts;

    return (
      <div className="pagination">
        {page > 1 &&
          <button onClick={() => this.previousPage(categoryId)} className="button button-rounded">
            <Message id="catalog_previous_page" />
          </button>
        }
        {(total > page * productsPerPage) &&
          <button onClick={() => this.nextPage(categoryId)} className="button button-rounded">
            <Message id="catalog_next_page" />
          </button>
        }
      </div>
    );
  }

  renderProducts() {
    const { products } = this.state;

    return products.map(product => {
      return (
        <div className="product" key={product.id}>
          <div className="product__image" onClick={() => this.showProduct(product.id)}>
            <img
              src={product.photoUrl}
              alt={product.name}
              onError={this.addDefaultSrc}
            />
          </div>
          <div className="product__data" onClick={() => this.showProduct(product.id)}>
            <h4>{product.name}</h4>
            {product.isPromo ? (
              <h5 className="price price--promo">
                <del>{utils.formatPrice(product.regularPrice)}</del>
                {utils.formatPrice(product.price)}
              </h5>
            ) : (
              <h5 className="price">{utils.formatPrice(product.price)}</h5>
            )}
          </div>
          <div className="product__buttons">
            <button onClick={() => this.addToCart(product)} className="button button-rounded button-3d button-green button-mini">
              <Icon icon={ICONS.SHOP_CART} />
            </button>
            <button onClick={() => this.showProduct(product.id)} className="button button-rounded button-mini">
              <Message id="show"/>
            </button>
          </div>
        </div>
      )
    })
  }

  pageCount() {
    const { total } = this.state;
    const { settings: { productsPerPage }} = consts;

    return Math.ceil(total / productsPerPage);
  }

  onClickFilter = () => {
    this.setState({
      showFilters: !this.state.showFilters
    })
  };

  hasActiveFilters() {
    const { filters } = this.state;

    return Object.keys(filters).length !== 0;
  }

  filtersActiveCount() {
    const { filters } = this.state;

    return Object.keys(filters).length;
  }

  render() {
    const { categoryData, loading, page, total, showFilters, filters } = this.state;

    if (loading) {
      return <CircularProgress />
    }

    return (
      <div className="catalog">
        {showFilters && (
          <Filters
            vendors={categoryData.vendors}
            statuses={categoryData.statuses}
            filters={filters}
            onClickFilter={this.onClickFilter.bind(this)}
            onClickFilterOption={this.onClickFilterOption.bind(this)}
          />
        )}
        <h1 className="catalog__header">{categoryData.categoryName}</h1>
        <div className="catalog__data">
          <div>
            <Message id="catalog_total_results" />{total}
          </div>
          <div>
            <Message id="catalog_page" />{`${page} / ${this.pageCount()}`}
          </div>
        </div>
        {this.hasActiveFilters() ? (
          <button className="button button-filter button-red" onClick={() => this.onClickFilter()}>
            <i>
              <Message id="products_filter" />
              {` (${this.filtersActiveCount()})`}
            </i>
          </button>
        ) : (
          <button className="button button-filter" onClick={() => this.onClickFilter()}>
            <Message id="products_filter" />
          </button>
        )}
        <div className="products">
          {this.renderProducts()}
        </div>
        {this.renderPagination()}
      </div>

    );
  }
}

export default connect()(Catalog);
