import CONSTS from "./consts";
import queryString from 'query-string'

const API_TEST = "API_TEST";
const API_TEST_SUCCESS = "API_TEST_SUCCESS";

const GET_TOKEN = "GET_TOKEN";
const GET_TOKEN_SUCCESS = "GET_TOKEN_SUCCESS";

const GET_APP = "GET_APP";
const GET_APP_SUCCESS = "GET_APP_SUCCESS";

const GET_PRODUCT = "GET_PRODUCT";
const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";

const GET_PAGE = "GET_PAGE";
const GET_PAGE_SUCCESS = "GET_PAGE_SUCCESS";

const GET_CATEGORY = "GET_CATEGORY";
const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";

const GET_SEARCH = "GET_SEARCH";
const GET_SEARCH_SUCCESS = "GET_SEARCH_SUCCESS";

const GET_PRODUCTS = "GET_PRODUCTS";
const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";

const GET_HOME_PRODUCTS = "GET_HOME_PRODUCTS";
const GET_HOME_PRODUCTS_SUCCESS = "GET_HOME_PRODUCTS_SUCCESS";

const GET_HOME_SLIDES = "GET_HOME_SLIDES";
const GET_HOME_SLIDES_SUCCESS = "GET_HOME_SLIDES_SUCCESS";

const GET_CATEGORIES = "GET_CATEGORIES";
const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";

const GET_SUB_CATEGORIES = "GET_SUB_CATEGORIES";
const GET_SUB_CATEGORIES_SUCCESS = "GET_SUB_CATEGORIES_SUCCESS";

const ADD_TO_CART = "ADD_TO_CART";
const ADD_TO_CART_SUCCESS = "ADD_TO_CART_SUCCESS";

const DELETE_FROM_CART = "DELETE_FROM_CART";
const DELETE_FROM_CART_SUCCESS = "DELETE_FROM_CART_SUCCESS";

const GET_CART_PRODUCTS = "GET_CART_PRODUCTS";
const GET_CART_PRODUCTS_SUCCESS = "GET_CART_PRODUCTS_SUCCESS";

const SET_BACK_URL = "SET_BACK_URL";

export {
  API_TEST_SUCCESS,
  SET_BACK_URL,
  GET_TOKEN, GET_TOKEN_SUCCESS,
  GET_APP, GET_APP_SUCCESS,
  GET_PRODUCTS, GET_PRODUCTS_SUCCESS,
  GET_HOME_SLIDES, GET_HOME_SLIDES_SUCCESS,
  GET_CATEGORIES, GET_CATEGORIES_SUCCESS,
  ADD_TO_CART, ADD_TO_CART_SUCCESS,
  DELETE_FROM_CART, DELETE_FROM_CART_SUCCESS,
}

export function apiTest() {
  return {
    type: API_TEST,
    payload: {
      request: {
        method: "get",
        url: CONSTS.apiUrl.test
      }
    }
  }
}

export function getToken() {
  return {
    type: GET_TOKEN,
    payload: {
      request: {
        method: "get",
        url: CONSTS.apiUrl.token
      }
    }
  }
}

export function getAppData() {
  return {
    type: GET_APP,
    payload: {
      request: {
        method: "get",
        url: CONSTS.apiUrl.app
      }
    }
  }
}

export function setBackUrl(url) {
  return {
    type: SET_BACK_URL,
    data: {
      url
    }
  }
}

export function getSearch(searchQuery) {
  return {
    type: GET_SEARCH,
    payload: {
      request: {
        method: "get",
        url: CONSTS.apiUrl.search
          .replace(':searchQuery', searchQuery)
      }
    }
  }
}

export function getCategory(categoryId) {
  return {
    type: GET_CATEGORY,
    payload: {
      request: {
        method: "get",
        url: CONSTS.apiUrl.category
          .replace(':categoryId', categoryId)
      }
    }
  }
}

export function getProducts(categoryId = '', page = 1, filters = []) {
  return {
    type: GET_PRODUCTS,
    payload: {
      request: {
        method: "get",
        url: CONSTS.apiUrl.products
          .replace(':categoryId', categoryId)
          .replace(':page', page) + '&' + queryString.stringify(filters),
        data: {
          filters
        }
      },
    }
  }
}

export function getHomeSlides() {
  return {
    type: GET_HOME_SLIDES,
    payload: {
      request: {
        method: "get",
        url: CONSTS.apiUrl.homeSlides
      }
    }
  }
}

export function getHomeProducts() {
  return {
    type: GET_HOME_PRODUCTS,
    payload: {
      request: {
        method: "get",
        url: CONSTS.apiUrl.homeProducts
      }
    }
  }
}

export function getProduct(productId = '') {
  return {
    type: GET_PRODUCT,
    payload: {
      request: {
        method: "get",
        url: CONSTS.apiUrl.product.replace(':productId', productId)
      }
    }
  }
}

export function getPage(pageId = '') {
  return {
    type: GET_PAGE,
    payload: {
      request: {
        method: "get",
        url: CONSTS.apiUrl.page.replace(':pageId', pageId)
      }
    }
  }
}

export function getCategories() {
  return {
    type: GET_CATEGORIES,
    payload: {
      request: {
        method: "get",
        url: CONSTS.apiUrl.categories
      }
    }
  }
}

export function getSubCategories(categoryId) {
  return {
    type: GET_SUB_CATEGORIES,
    payload: {
      request: {
        method: "get",
        url: CONSTS.apiUrl.subCategories.replace(':categoryId', categoryId)
      }
    }
  }
}

export function getCartProducts() {
  return {
    type: GET_CART_PRODUCTS,
    payload: {
      request: {
        method: "get",
        url: CONSTS.apiUrl.cart.get
      }
    }
  }
}

export function addToCart(product) {
  return {
    type: ADD_TO_CART,
    data: {
      product
    }
  }
}

export function deleteFromCart(productId) {
  return {
    type: DELETE_FROM_CART,
    data: {
      productId
    }
  }
}