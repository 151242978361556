import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import appReducer from './appReducer.jsx'
import orderReducer from './order/orderReducer.jsx'

export default combineReducers({
  appReducer,
  orderReducer,
  form: formReducer.plugin({
    ClientBasicForm: (state, action) => {
      switch (action.type) {
        default:
          return state;
      }
    },
    OrderForm: (state, action) => {
      switch (action.type) {
        default:
          return state;
      }
    },
  })
})