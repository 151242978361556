import CONSTS from "./../consts";

const GET_ORDER_SHIPPINGS = "GET_ORDER_SHIPPINGS";
const GET_ORDER_SHIPPINGS_SUCCESS = "GET_ORDER_SHIPPINGS_SUCCESS";

const POST_PLACE_ORDER = "POST_PLACE_ORDER";
const POST_PLACE_ORDER_SUCCESS = "POST_PLACE_ORDER_SUCCESS";

const GET_ORDER = "GET_ORDER";
const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";

const GET_ORDER_PAYMENTS = "GET_ORDER_PAYMENTS";
const GET_ORDER_PAYMENTS_SUCCESS = "GET_ORDER_PAYMENTS_SUCCESS";

const GET_INPOST_MACHINES = "GET_INPOST_MACHINES";
const GET_INPOST_MACHINES_SUCCESS = "GET_INPOST_MACHINES_SUCCESS";

const SET_SHIPPING = "SET_SHIPPING";
const SET_PAYMENT = "SET_PAYMENT";

export {
  GET_ORDER_SHIPPINGS_SUCCESS,
  GET_ORDER_PAYMENTS_SUCCESS,
  GET_INPOST_MACHINES_SUCCESS,
  POST_PLACE_ORDER_SUCCESS,
  GET_ORDER_SUCCESS,
  SET_SHIPPING
}

export function setShipping(shipping, inpostMachine = null) {
  return {
    type: SET_SHIPPING,
    data: {
      shipping,
      inpostMachine
    }
  }
}

export function setPayment(payment) {
  return {
    type: SET_PAYMENT,
    data: {
      payment
    }
  }
}

export function getOrderShippings() {
  return {
    type: GET_ORDER_SHIPPINGS,
    payload: {
      request: {
        method: "post",
        url: CONSTS.apiUrl.cart.shippings,
        data: {
          weightTotal: 1,
          totalCost: 100
        }
      }
    }
  }
}

export function placeOrder(products, shippingId, paymentId, clientData, orderData) {
  return {
    type: POST_PLACE_ORDER,
    payload: {
      request: {
        method: "post",
        url: CONSTS.apiUrl.cart.placeOrder,
        data: {
          products,
          shippingId,
          paymentId,
          clientData,
          orderData
        }
      }
    }
  }
}

export function getOrder(id) {
  return {
    type: GET_ORDER,
    payload: {
      request: {
        method: "post",
        url: CONSTS.apiUrl.cart.getOrder.replace(':orderId', id),
      }
    }
  }
}

export function getOrderPayments() {
  return {
    type: GET_ORDER_PAYMENTS,
    payload: {
      request: {
        method: "post",
        url: CONSTS.apiUrl.cart.payments,
      }
    }
  }
}

export function getInpostMachines(postCode, paymentAvailable) {
  const url = CONSTS.apiUrl.cart.inpostMachines
    .replace(':postCode', postCode)
    .replace(':paymentAvailable', paymentAvailable);

  return {
    type: GET_INPOST_MACHINES,
    payload: {
      request: {
        method: "get",
        url: url
      }
    }
  }
}
