import React, {Component} from 'react';
import {connect} from "react-redux";
import Message from "../helpers/Message";
import CloseIcon from '@material-ui/icons/Close';

class Filter extends Component {


  constructor(props) {
    super(props);
    const { filters, url } = props;

    this.state = {
      selectedIds: filters[url] ? filters[url] : []
    };
  }

  isSelected = id => {
    const { filters, url } = this.props;

    if (!filters[url]) {
      return false
    }

    return filters[url].includes(id);
  };

  setFilter = selectedIds => {
    const { filters, url, onClickFilterOption } = this.props;

    if (selectedIds.length === 0) {
      delete filters[url];
    } else {
      filters[url] = selectedIds;
    }

    onClickFilterOption(filters);
  };

  onClickSelected = (id) => {
    const { selectedIds } = this.state;

    if (!this.isSelected(id)) {
      selectedIds.push(id);
      this.setState({
        selectedIds: selectedIds
      });
      this.setFilter(selectedIds);

      return
    }

    const newSelectedIds = selectedIds.filter(selectedId => {
      return id !== selectedId
    });

    this.setState({
      selectedIds: newSelectedIds
    });
    this.setFilter(newSelectedIds);
  };

  render() {
    const {name, data} = this.props;
    const {selectedIds} = this.state;

    if (data.length <= 1 && selectedIds.length === 0) {
      return null;
    }

    return (
      <div className="filter">
        <h4><Message id={name} /></h4>
        <ul>
        {data.map(d => {
          return (
            <li className={this.isSelected(d.id) ? "selected" : ""} onClick={() => this.onClickSelected(d.id)}>
              {this.isSelected(d.id) && (
                <CloseIcon/>
              )}
              {d.name}
            </li>
          )
        })}
        </ul>
      </div>
    );
  };
}

export default connect()(Filter);
