import React from 'react';
import Message from "./../../helpers/Message"

const FormTextarea = field => {
  const {
    input,
    meta: { touched, error },
    placeholder
  } = field;

  return (
    <div className="form__field-textarea">
        <textarea
          className="comment-add"
          placeholder={placeholder}
          {...input}
        />
      {(touched && error) ? <div className="form__error"><Message id={error} /></div> : ''}
    </div>
  );
};

export default FormTextarea;