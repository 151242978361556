import React, {Component} from 'react';
import { connect } from "react-redux";
import {getInpostMachines, getOrderShippings, setShipping} from "./orderActions";
import Message from "../helpers/Message";
import utils from "../utils";
import CONSTS from "../consts";
import Button from '@material-ui/core/Button';

class Shippings extends Component {

  state = {
    inpostMachines: null,
    selectInpostMachine: false,
    selectedInpostMachine: null,
    selected: null
  };

  componentWillMount() {
    const { dispatch } = this.props;

    dispatch(getOrderShippings());
  }

  goOrderClientData() {
    const { selected, selectedInpostMachine } = this.state;
    const { dispatch, history } = this.props;
    const { url } = CONSTS;

    setTimeout(() => {
      dispatch(setShipping(selected, selectedInpostMachine));
      history.push(url.orderClientData);
    }, 100)
  }

  onInpostMachineClick = inpostMachine => {
    this.setState({
      selectedInpostMachine: inpostMachine.code
    }, () => {
      const { history } = this.props;
      const { selected: { paymentType } } = this.state;
      const { url, types: {shippingPayment: {transfer}} } = CONSTS;

      if (paymentType === transfer) {
        history.push(url.orderPayment);
        return;
      }

      this.goOrderClientData();
    })
  };

  onShippingClick = shipping => {
    const { dispatch, history } = this.props;
    const { url, types: {shippingPayment, shippingTypes} } = CONSTS;

    this.setState({
      selected: shipping
    }, () => {
      setTimeout(() => {
        dispatch(setShipping(shipping));
        if (shipping.type === shippingTypes.inpostMachine) {
          this.setState({
            selectInpostMachine: true
          });

          return;
        }

        if (shipping.paymentType === shippingPayment.transfer) {
          history.push(url.orderPayment);

          return;
        }

        this.goOrderClientData();
      }, 100)
    })
  };

  renderType = type => {
    const { types: {shippingTypes} } = CONSTS;

    return null;

    // return (
    //   <h2>
    //     <Message id={`shipping_type_${shippingTypes[type]}`}/>
    //   </h2>
    // )
  };

  handlePostCode = code => {
    const { dispatch } = this.props;

    if (code.length === 6) {
      dispatch(getInpostMachines(code, 0)).then(response => {
        this.setState({
          inpostMachines: utils.getResponse(response)
        })
      })
    }
  };

  renderMachines() {
    const { selectedInpostMachine: selected, inpostMachines } = this.state;

    if (inpostMachines === null) {
      return null;
    }

    return (
      <ul className="inpost-machines">
        {inpostMachines.map(machine => {
          return (
            <li className={selected === machine.code ? "machine machine--selected" : "machine"} onClick={() => this.onInpostMachineClick(machine)}>
              <strong>{machine.code}</strong> - <span className="city">{machine.address}</span><br />
              {machine.description}<br />
              <span className="address">{machine.town}</span>
            </li>
          );
        })}
      </ul>
    )
  }

  renderChooseInpostMachine() {
    return (
      <div>
        <Button onClick={() => this.setState({selectInpostMachine: false})}>
          <Message id="catalog_back_to_top" />
        </Button>
        <h1>
          <Message id="choose_inpost_machine"/>
        </h1>
        <form className="form">
          <div className="form__field-input">
            <input type="text" placeholder="Podaj kod pocztowy" maxLength={6} onChange={e => this.handlePostCode(e.target.value)}/>
            <p className="help-block">
              <Message id="post_code_valid_format"/>
            </p>
          </div>
        </form>
        {this.renderMachines()}
      </div>
    )
  }

  isShippingSelected = shipping => {
    const { selected } = this.state;

    if (selected === null) {
      return false
    }

    return selected.id === shipping.id;
  };

  renderChoose() {
    const { shippings } = this.props;

    return (
      <div>
        <h1>
          <Message id="choose_shipping"/>
        </h1>
        {Object.keys(shippings).map(shippingType => {
          return (
            <div>
              {this.renderType(shippingType)}
              <div className="shippings">
                {shippings[shippingType].map(shipping => {
                  return (
                    <div className={this.isShippingSelected(shipping) ? "shipping shipping--selected" : "shipping"} onClick={() => this.onShippingClick(shipping)}>
                      <h4 key={shipping.id}>{shipping.name}</h4>
                      <div className="price">
                        {utils.formatPrice(shipping.cost)}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          );
        })}
      </div>
    )
  }

  render() {
    const { shippings } = this.props;
    const { selectInpostMachine } = this.state;

    if (shippings.length === 0) {
      return null;
    }

    const content = !selectInpostMachine
      ? this.renderChoose()
      : this.renderChooseInpostMachine();

    return (
      <div className="shippings-page">
        {content}
      </div>
    );
  }
}

const normalizePostCode = (value, previousValue) => {
  if (!value) {
    return value
  }
  const onlyNums = value.replace(/[^\d]/g, '')
  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (onlyNums.length === 2) {
      return onlyNums + '-'
    }
  }
  if (onlyNums.length <= 2) {
    return onlyNums
  }
  if (onlyNums.length <= 5) {
    return onlyNums.slice(0, 2) + '-' + onlyNums.slice(2)
  }
};

const mapStateToProps = state => ({
  shippings: state.orderReducer.shippings
});

export default connect(mapStateToProps)(Shippings);
