import React from 'react';

function Login() {
  return (
    <h2>
      Login
    </h2>
  );
}

export default Login;
